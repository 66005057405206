<template>
  <div>
    <!-- 给学生设置自定义的考卷 -->
    <el-dialog title="編輯學生的考卷" :visible.sync="setTests" width="60%">
      <div style="padding-top: 0">
        <div v-for="(exam, index) in reportcards" :key="index">
          <h5>Test {{ exam.which_week }}</h5>
          <div class="row">
            <div class="col-sm-6" style="display:flex; margin-bottom:20px">
              <div style="margin-right:10px;line-height:40px;width: 4rem;">
                <b>選擇類型</b>
              </div>
              <el-select
                v-model="exam.type"
                placeholder="選擇考卷類型"
                style="flex:1"
              >
                <el-option value="satmocks" label="SAT線上考試"></el-option>
                <el-option value="ivyway" label="Ivyway"></el-option>
              </el-select>
            </div>
            <div
              class="col-sm-6"
              style="display:flex; margin-bottom:20px"
              v-if="exam !== undefined"
            >
              <template v-if="exam.type === 'ivyway'">
                <div style="margin-right:10px;line-height:40px;width: 4rem;">
                  <b>選擇考試</b>
                </div>
                <el-select
                  filterable
                  style="flex: 1"
                  v-model="exam.new_exam_id"
                  @change="
                    $forceUpdate();
                    exam.new_user_exam_id = 0;
                  "
                >
                  <el-option label="暫不設置考卷。" :value="0"></el-option>
                  <el-option
                    v-for="(item, index) in tests || []"
                    :key="index"
                    :label="item.full_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </template>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6" style="display:flex; margin-bottom:20px">
              <div style="margin-right:10px;line-height:40px;width: 4rem;">
                <b>選擇考卷</b>
              </div>
              <el-select
                style="flex:1"
                v-show="exam.type === 'ivyway'"
                filterable
                v-model="exam.new_user_exam_id"
                @change="$forceUpdate()"
              >
                <el-option label="新考卷" :value="0"></el-option>
                <el-option
                  v-if="
                    !(exam.sat_score || exam.act_score) &&
                      exam.new_exam_id === exam.exam_id &&
                      exam.user_exam_id !== 0
                  "
                  label="權限已開啟,等待考試完成。"
                  :value="exam.user_exam_id"
                ></el-option>
                <template v-for="(item, index) in changeStudentExams">
                  <el-option
                    :key="index"
                    :label="
                      item.exam.full_name +
                        ' [' +
                        instant.formatDate(item.updated_at) +
                        ']'
                    "
                    :value="item.id"
                    v-if="exam.new_exam_id === item.exam_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-show="exam.type === 'satmocks'"
                filterable
                style="width:80%"
                v-model="exam.onlineSATIndex"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="(item, index) in changeSATStudentExams"
                  :key="index"
                  :label="
                    `${item.exam.title}: [${item.total_score}]
                        ${instant.formatDate(item.updated_at)}
                        `
                  "
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <hr />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setTests = false">{{
          $t("button.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveStudentExams">{{
          $t("button.save")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 给学生设置周考 -->
    <el-dialog title="設置考卷" :visible.sync="setResult" width="60%">
      <div>
        <h5>TEST {{ studentExam.whichTest }}: {{ studentExam.test }}</h5>
        <hr />
        <div class="row form-group">
          <!--test type-->
          <b class="col-sm-2" for="test_type" style="line-height: 40px">
            Student
          </b>
          <b class="col-sm-10" for="test_type" style="line-height: 40px">
            {{ studentExam.student }}
          </b>
        </div>
        <div class="row form-group">
          <!--test type-->
          <b class="col-sm-2" for="test_type" style="line-height: 40px">
            * Exams
          </b>
          <div class="col-sm-10">
            <el-select v-model="studentExam.user_exam" style="width:100%">
              <el-option
                v-for="(item, index) in studentExam.exams"
                :key="index"
                :label="item.exam.full_name"
                :value="index"
              >
                {{ item.exam.full_name }} ({{
                  instant.formatDateTime(item.updated_at)
                }})
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setResult = false">{{
          $t("button.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveStudentExam()">{{
          $t("button.save")
        }}</el-button>
      </span>
    </el-dialog>

    <template v-if="unitSlug">
      <el-alert
        class="mb-4"
        title="下面列出的是課本QRCode對應的練習"
        type="warning"
        :closable="false"
      >
        <div>
          <!-- <br /> -->
          如果沒有對應的練習，請 <b>聯繫老師</b> 或
          <router-link
            :to="{
              name: $route.name,
              params: { ...$route.params },
              query: { ...$route.query, unit_slug: null }
            }"
            >點擊此處 </router-link
          >顯示班級的全部練習。
        </div>
      </el-alert>
    </template>
    <div
      style="overflow: auto;"
      v-if="
        classes &&
          classes.student_class_tickets &&
          classes.student_class_tickets.length > 0
      "
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="text-center align-middle" v-if="!disableEdit">
              <label style="margin-bottom:0"
                ><input
                  type="checkbox"
                  name="check_students"
                  v-model="classes.checkedAll"
                  @click="checkedAll(classes)"
                />&nbsp; <b>All</b></label
              >
            </th>
            <th class="text-center align-middle">Students</th>
            <th
              class="text-center align-middle"
              v-for="(test, index) in classes.session_class_assign_tests"
              :key="index"
              v-show="getType(test.exam ? test.exam.category : 0) === type"
              style="width:400px"
            >
              <div class="mb-2">
                <el-tooltip
                  effect="dark"
                  :content="test.exam.title"
                  placement="top"
                >
                  <!-- <span>TEST {{ index }}</span> -->
                  <span>{{ getIvywayExamTitle(test.exam.title) }}</span>
                </el-tooltip>
              </div>
              <template v-if="isAdmin || isTeacher">
                <el-tooltip effect="dark" content="Analysis" placement="top">
                  <a
                    class="ml-2"
                    :href="
                      '/session-classes/analysis/onlineSATDetail?class_id=' +
                        section.id +
                        '&week=' +
                        test.which_week +
                        '&exam_type=' +
                        getExamType(test.exam ? test.exam.category : 0)
                    "
                    target="_blank"
                  >
                    <el-button type="success" size="mini" plain>
                      <i class="fas fa-chart-line"></i>
                    </el-button>
                  </a>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="給所有學生開通新的考卷"
                  placement="top"
                >
                  <el-button
                    class="ml-3"
                    type="primary"
                    size="mini"
                    @click="setExamAll(section.id, test.id)"
                  >
                    <i class="fa fa-plus"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="給所有學生發送考試鏈接"
                  placement="top"
                  v-show="hide"
                >
                  <el-button
                    class="ml-3"
                    type="primary"
                    size="mini"
                    @click="sendExamMailAll(section.id, index)"
                  >
                    <i class="fa fa-envelope"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </th>
            <th class="text-center align-middle">
              <el-tooltip
                effect="dark"
                content="Improvement for each subject since the first test"
                placement="top"
              >
                <span>
                  <i class="far fa-question-circle"></i>
                  &nbsp;Super Score
                </span>
              </el-tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, index) in displayStudents" :key="index">
            <td v-if="!disableEdit">
              <label>
                <input
                  type="checkbox"
                  name="check_students"
                  v-model="student.is_checked"
                  @click="checked(classes, student)"
                />
                <b> {{ index + 1 }}</b>
              </label>
            </td>
            <td>
              <a :href="'/user/' + student.student_user_id">
                {{ user.displayName(student.first_name, student.last_name) }}
              </a>
            </td>
            <td
              v-for="(exam, index) in section.session_class_assign_tests"
              :key="index"
              v-show="getType(exam.exam.category) === type"
              class="add-result"
              style=" vertical-align: top;"
            >
              <div style="min-width:300px;margin: 0 auto;">
                <div class="text-right mb-2" v-if="isAdmin || isTeacher">
                  <el-tooltip effect="dark" content="Set Test" placement="top">
                    <el-button
                      class="p-0"
                      type="text"
                      @click="
                        selectExam(
                          student.student_class_ticket_exams &&
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ]
                            ? student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.id
                            : null,
                          student,
                          exam.id,
                          exam.exam.id,
                          student.student_user_id
                        )
                      "
                    >
                      <i class="fas fa-users-cog"></i>
                    </el-button>
                  </el-tooltip>
                </div>
                <div
                  v-if="
                    student.student_class_ticket_exams &&
                      student.student_class_ticket_exams[
                        'Test' + exam.which_week
                      ]
                  "
                  class="text-center"
                >
                  <template
                    v-if="
                      student.student_class_ticket_exams[
                        'Test' + exam.which_week
                      ].user_exam_type === 'App\\Entities\\SatMocks\\UserExam'
                    "
                  >
                    <template
                      v-if="
                        student.student_class_ticket_exams[
                          'Test' + exam.which_week
                        ].user_exam
                      "
                    >
                      <template>
                        <template v-if="isAdmin || isTeacher">
                          <OnlineSatScore
                            :escore="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.result
                                ? student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.result['english_score']
                                : 0
                            "
                            :mscore="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.result
                                ? student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.result['math_score']
                                : 0
                            "
                            :tscore="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.total_score
                            "
                            :question_count="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].exam.question_count
                            "
                            :testType="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].exam.category
                            "
                            :rscore_up="0"
                            :gscore_up="0"
                            :escore_up="0"
                            :mscore_up="0"
                            :score_up="0"
                            :canEdit="false"
                            :userExamId="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.id
                            "
                            :title="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].exam.title
                            "
                          ></OnlineSatScore>
                          <div
                            v-if="
                              !student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.result &&
                                student.student_class_ticket_exams[
                                  'Test' + exam.which_week
                                ].user_exam.is_finished == 0
                            "
                            class="mb-2 text-center"
                          >
                            <el-tooltip
                              effect="dark"
                              :content="
                                `[${instant.formatGoogleTime(
                                  student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.created_at
                                )}] 考試已開通，請通知學生作答`
                              "
                              placement="top"
                            >
                              <el-tag>
                                <b class="text-info">
                                  <i class="fas fa-user-check"></i>
                                  [{{
                                    instant.formatGoogleTime(
                                      student.student_class_ticket_exams[
                                        "Test" + exam.which_week
                                      ].user_exam.created_at
                                    )
                                  }}]考試已開通
                                </b>
                              </el-tag>
                            </el-tooltip>
                          </div>
                          <div
                            class="mb-2"
                            v-if="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.result &&
                                student.student_class_ticket_exams[
                                  'Test' + exam.which_week
                                ].user_exam.is_finished == 0
                            "
                          >
                            <el-tooltip
                              effect="dark"
                              :content="
                                `[${instant.formatGoogleTime(
                                  student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.created_at
                                )}] 考試已開通，學生正在作答`
                              "
                              placement="top"
                            >
                              <el-tag>
                                <b class="text-info">
                                  <i class="fas fa-user-check"></i>
                                  [{{
                                    instant.formatGoogleTime(
                                      student.student_class_ticket_exams[
                                        "Test" + exam.which_week
                                      ].user_exam.created_at
                                    )
                                  }}] 學生正在作答
                                </b>
                              </el-tag>
                            </el-tooltip>
                          </div>
                        </template>
                        <template v-else>
                          <OnlineSatScore
                            v-if="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.is_finished == 1
                            "
                            :escore="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.result
                                ? student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.result['english_score']
                                : 0
                            "
                            :mscore="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.result
                                ? student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.result['math_score']
                                : 0
                            "
                            :tscore="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.total_score
                            "
                            :question_count="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].exam.question_count
                            "
                            :testType="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].exam.category
                            "
                            :rscore_up="0"
                            :gscore_up="0"
                            :escore_up="0"
                            :mscore_up="0"
                            :score_up="0"
                            :canEdit="false"
                            :userExamId="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.id
                            "
                            :title="exam.title"
                          ></OnlineSatScore>
                          <div
                            v-if="
                              student.student_class_ticket_exams[
                                'Test' + exam.which_week
                              ].user_exam.is_finished == 0
                            "
                            class="text-center"
                          >
                            <a
                              :href="
                                `${satIvywayUrl}/test/${getPracticeTypeLink(
                                  student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].exam.category
                                )}/${
                                  student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].exam.test_id
                                }?user_exam_id=${
                                  student.student_class_ticket_exams[
                                    'Test' + exam.which_week
                                  ].user_exam.id
                                }&token=${token}`
                              "
                            >
                              <el-button type="success" round>
                                <i class="fas fa-play" />
                                Start a test
                              </el-button>
                            </a>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <el-radio
                      v-if="isMergeCheck"
                      :label="
                        student.student_class_ticket_exams[
                          `Test${exam.which_week}`
                        ]
                          ? student.student_class_ticket_exams[
                              `Test${exam.which_week}`
                            ].id
                          : null
                      "
                      :value="
                        mergeCheckDependent[student.class_id][
                          `Test${exam.which_week}`
                        ]
                      "
                      @change="
                        () =>
                          $emit(
                            'checkedConflictClassExam',
                            student.class_id,
                            `Test${exam.which_week}`,
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ]
                              ? student.student_class_ticket_exams[
                                  'Test' + exam.which_week
                                ].id
                              : null
                          )
                      "
                    />
                    <div
                      v-if="
                        student.student_class_ticket_exams !== undefined &&
                          student.student_class_ticket_exams[
                            'Test' + exam.which_week
                          ] !== undefined &&
                          (student.student_class_ticket_exams[
                            'Test' + exam.which_week
                          ].sat_score !== null ||
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].act_score !== null)
                      "
                    >
                      <!-- SAT的考试 -->
                      <div
                        v-if="
                          student.student_class_ticket_exams[
                            'Test' + exam.which_week
                          ].sat_score !== null
                        "
                      >
                        <sat-score
                          :rscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.reading_score * 10
                          "
                          :wscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.writing_score * 10
                          "
                          :escore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.section_english_score
                          "
                          :mscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.section_math_score
                          "
                          :tscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.total_score
                          "
                          :rscore_up="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.rscore_up * 10
                          "
                          :gscore_up="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.gscore_up * 10
                          "
                          :escore_up="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.escore_up
                          "
                          :mscore_up="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.mscore_up
                          "
                          :score_up="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].sat_score.score_up
                          "
                          :canEdit="!disableEdit"
                          :userExamId="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].user_exam_id
                          "
                          @setExam="
                            setExam(
                              student,
                              exam.full_name,
                              index,
                              classes.course_session_id
                            )
                          "
                          @removeExam="removeStudentExam(student, index)"
                        ></sat-score>
                      </div>
                      <!-- ACT的考试 -->
                      <div
                        v-if="
                          student.student_class_ticket_exams[
                            'Test' + exam.which_week
                          ].act_score !== null
                        "
                      >
                        <act-score
                          :escore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].act_score.english_score
                          "
                          :mscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].act_score.math_score
                          "
                          :rscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].act_score.reading_score
                          "
                          :sscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].act_score.science_score
                          "
                          :tscore="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].act_score.total_score
                          "
                          :escore_up="0"
                          :mscore_up="0"
                          :rscore_up="0"
                          :sscore_up="0"
                          :score_up="0"
                          :canEdit="!disableEdit"
                          :userExamId="
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].user_exam_id
                          "
                          @setExam="
                            setExam(
                              student,
                              exam.full_name,
                              index,
                              classes.course_session_id
                            )
                          "
                          @removeExam="removeStudentExam(student, index)"
                        ></act-score>
                      </div>
                      <!-- TOEFL的考试 -->
                      <div
                        v-if="
                          student.student_class_ticket_exams !== undefined &&
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].toelf_score &&
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].toelf_score !== null
                        "
                      >
                        <toefl-score
                          :rscore="student.reading_score"
                          :lscore="student.listening_score"
                          :sscore="student.speaking_score"
                          :wscore="student.writing_score"
                          :tscore="student.total_score"
                          :rscore_up="0"
                          :gscore_up="0"
                          :escore_up="0"
                          :mscore_up="0"
                          :score_up="0"
                          :canEdit="false"
                          userExamId=""
                        ></toefl-score>
                      </div>
                    </div>
                    <div v-else class="text-center">
                      <div
                        v-if="
                          student.student_class_ticket_exams !== undefined &&
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ] &&
                            student.student_class_ticket_exams[
                              'Test' + exam.which_week
                            ].user_exam_id > 0
                        "
                      >
                        <el-tag>
                          <b class="text-info">
                            考試已開通，請通知學生作答
                          </b>
                        </el-tag>
                      </div>
                    </div>
                  </template>
                </div>
                <template v-else>
                  <div class="text-center" v-if="isAdmin || isTeacher">
                    <el-tooltip
                      effect="dark"
                      content="開通新的考卷"
                      placement="top"
                    >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="
                          setExamUser(student.student.id, section.id, exam.id)
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                  </div>
                  <div v-else class="text-center">
                    <el-tag>
                      <b class="text-info">
                        <i class="fas fa-user-check"></i>
                        等待老師開通
                      </b>
                    </el-tag>
                  </div>
                </template>
              </div>
            </td>
            <td>
              <div v-if="student.max_score > 0">
                <sat-score
                  :rscore="student.max_rscore * 10"
                  :wscore="student.max_gscore * 10"
                  :escore="student.max_escore"
                  :mscore="student.max_mscore"
                  :tscore="student.max_score"
                  :rscore_up="student.max_rscore_up * 10"
                  :gscore_up="student.max_gscore_up * 10"
                  :escore_up="student.max_escore_up"
                  :mscore_up="student.max_mscore_up"
                  :score_up="student.max_score_up"
                  :canEdit="false"
                  userExamId=""
                ></sat-score>
              </div>
            </td>
            <!-- <td
              v-if="!disableEdit"
              style="vertical-align:middle;text-align:center"
            >
              <el-button
                type="text"
                @click="
                  editReportcards(
                    student.student_class_ticket_exams,
                    student.id,
                    student.student_user_id
                  )
                "
              >
                <i class="fas fa-user-cog"></i>
              </el-button>
            </td> -->
          </tr>
          <tr v-if="!disableAvg">
            <td v-if="!disableEdit"></td>
            <td><b>AVG</b></td>
            <td
              v-for="(exam, index) in classes.session_class_assign_tests"
              :key="index"
              v-show="getType(exam.exam.category) === type"
            >
              <div class="text-center" v-if="exam.t_total > 0">
                <sat-score
                  v-if="exam.count > exam.onlineCount"
                  :rscore="showScore(exam.r_total * 10, exam.count)"
                  :wscore="showScore(exam.g_total * 10, exam.count)"
                  :escore="
                    showScore(exam.r_total * 10, exam.count) +
                      showScore(exam.g_total * 10, exam.count)
                  "
                  :mscore="showScore(exam.m_total, exam.count)"
                  :tscore="
                    showScore(exam.r_total * 10, exam.count) +
                      showScore(exam.g_total * 10, exam.count) +
                      showScore(exam.m_total, exam.count)
                  "
                  :rscore_up="exam.r_up"
                  :gscore_up="exam.g_up"
                  :escore_up="exam.e_up"
                  :mscore_up="exam.m_up"
                  :score_up="exam.t_up"
                  :canEdit="false"
                  userExamId=""
                  :classId="section.id"
                  :week="index"
                ></sat-score>
                <OnlineSatScore
                  v-if="exam.count < exam.onlineCount"
                  :escore="showScore(exam.e_total, exam.onlineCount)"
                  :mscore="showScore(exam.m_total, exam.onlineCount)"
                  :tscore="showScore(exam.t_total, exam.onlineCount)"
                  :question_count="exam.exam.question_count"
                  :testType="exam.exam.category"
                  :rscore_up="0"
                  :gscore_up="0"
                  :escore_up="0"
                  :mscore_up="0"
                  :score_up="0"
                  :canEdit="false"
                ></OnlineSatScore>
              </div>
            </td>
            <!-- <td>
              <div>
                <sat-score
                  :rscore="
                    showScore(
                      getSuperScoreTotal.max_rscore * 10,
                      getSuperScoreCount
                    )
                  "
                  :wscore="
                    showScore(
                      getSuperScoreTotal.max_gscore * 10,
                      getSuperScoreCount
                    )
                  "
                  :escore="
                    showScore(getSuperScoreTotal.max_escore, getSuperScoreCount)
                  "
                  :mscore="
                    showScore(getSuperScoreTotal.max_mscore, getSuperScoreCount)
                  "
                  :tscore="
                    showScore(getSuperScoreTotal.max_score, getSuperScoreCount)
                  "
                  :rscore_up="
                    showScore(
                      getSuperScoreTotal.max_rscore_up * 10,
                      getSuperScoreCount
                    )
                  "
                  :gscore_up="
                    showScore(
                      getSuperScoreTotal.max_gscore_up * 10,
                      getSuperScoreCount
                    )
                  "
                  :escore_up="
                    showScore(
                      getSuperScoreTotal.max_escore_up,
                      getSuperScoreCount
                    )
                  "
                  :mscore_up="
                    showScore(
                      getSuperScoreTotal.max_mscore_up,
                      getSuperScoreCount
                    )
                  "
                  :score_up="
                    showScore(
                      getSuperScoreTotal.max_score_up,
                      getSuperScoreCount
                    )
                  "
                  :canEdit="false"
                  userExamId=""
                />
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>
      <el-dialog
        class="class-link"
        title="Set Test"
        :visible.sync="showSelectExam"
        width="60%"
      >
        <div v-if="studentExams">
          <el-table height="500" :data="studentExams">
            <el-table-column label="Test" min-width="100">
              <template slot-scope="scope">
                <el-tag
                  class="mr-2"
                  type="warning"
                  v-if="
                    scope.row.other_status &&
                      scope.row.other_status.adaptive_practice_id
                  "
                >
                  <b>Adaptive</b>
                </el-tag>
                <span v-if="!isAdmin && scope.row.is_finished === 0">
                  {{ scope.row.exam.title }}
                </span>
                <span v-else>{{ scope.row.exam.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Status" width="120">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.is_finished === 0 ? 'info' : 'success'"
                  disable-transitions
                >
                  <span v-if="scope.row.is_finished === 0">
                    Incomplete
                  </span>
                  <b v-else>Completed</b>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Date" width="100">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    moment(scope.row.updated_at).format('YYYY-MM-DD HH:mm')
                  "
                  placement="top"
                >
                  <span>{{
                    instant.formatGoogleTime(scope.row.updated_at)
                  }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="Score" width="100">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ scope.row.total_score }}
                </b>
              </template>
            </el-table-column>
            <el-table-column label="Actions" width="100">
              <template slot-scope="scope">
                <template>
                  <el-tooltip
                    class="ml-2"
                    effect="dark"
                    content="Select"
                    placement="top"
                    v-if="now_user_exam_id !== scope.row.id"
                  >
                    <el-button
                      @click="reSetTest(scope.row.id)"
                      type="primary"
                      size="mini"
                    >
                      <i class="fas fa-exchange-alt"></i>
                    </el-button>
                  </el-tooltip>
                  <b class="text-success" v-else>
                    <i class="fa fa-check"></i>
                    Selected
                  </b>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <div class="text-center mt-3 mb-5">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="10"
              :current-page="SATPage"
              @current-change="changePage"
              :total="pageTotal"
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SatScore from "@/views/sessionClasses/components/SatScore.vue";
import OnlineSatScore from "@/views/sessionClasses/components/OnlineSatScore.vue";
import ActScore from "@/views/sessionClasses/components/ActScore.vue";
import ToeflScore from "@/views/sessionClasses/components/ActScore.vue";
import courseApi from "@/apis/course";
import Tests from "@/apis/tests.js";
import { instant, user } from "@ivy-way/material";
import role from "@/mixins/role";
import _ from "lodash";
import moment from "moment";

export default {
  props: [
    "testState",
    "section",
    "disableAvg",
    "disableEdit",
    "isMergeCheck",
    "mergeCheckDependent",
    "displayStudentUserIds",
    "type"
  ],
  mixins: [role],
  components: {
    SatScore,
    OnlineSatScore,
    ActScore,
    ToeflScore
  },
  data() {
    return {
      moment,
      classes: null,
      setResult: false,
      setTests: false,
      reportcards: [],
      studentExam: {
        student_class_ticket_id: 0,
        test: "",
        student: "",
        whichTest: 0,
        exams: [],
        user_exam: "",
        exam_id: 0,
        sessionClass: 0
      },
      activeStudent_class_ticket_id: null,
      tests: [],
      changeStudentExams: [],
      changeSATStudentExams: [],
      form: null,
      showSelectExam: false,
      studentExams: null,
      SATPage: 1,
      pageTotal: 0,
      nowStudentClassTicket: null,
      nowSessionClassExamId: null,
      now_user_id: null,
      now_user_exam_id: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    satIvywayUrl() {
      return process.env.VUE_APP_SAT_IVYWAY_FRONTEND_DOMAIN;
    },
    unitSlug() {
      return this.$route.query.unit_slug;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    needToFilterStudent() {
      return this.displayStudentUserIds;
    },
    displayStudents() {
      if (this.displayStudentUserIds) {
        return this.classes.student_class_tickets.filter(
          ({ student_user_id }) =>
            this.displayStudentUserIds.includes(student_user_id)
        );
      } else {
        return this.classes.student_class_tickets;
      }
    },
    getSuperScoreTotal() {
      let superScoreTotal = {
        max_escore: 0,
        max_escore_up: 0,
        max_gscore: 0,
        max_gscore_up: 0,
        max_mscore: 0,
        max_mscore_up: 0,
        max_rscore: 0,
        max_rscore_up: 0,
        max_score: 0,
        max_score_up: 0
      };
      (this.classes.student_class_tickets || []).forEach(student => {
        for (const score in superScoreTotal) {
          superScoreTotal[score] += student[score];
        }
      });
      return superScoreTotal;
    },
    getSuperScoreCount() {
      let count = 0;
      (this.classes.student_class_tickets || []).forEach(student => {
        if (student.max_escore !== 0) {
          count += 1;
        }
      });
      return count;
    }
  },
  watch: {
    section() {
      this.initialize();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async reSetTest(user_exam_id) {
      const res = await Tests.reSetTest(this.nowSessionClassExamId, {
        student_class_ticket_id: this.nowStudentClassTicket.id,
        user_exam_id: user_exam_id,
        user_exam_type: "App\\Entities\\SatMocks\\UserExam"
      });
      this.showSelectExam = false;
      this.$emit("getSessions");
    },
    selectExam(
      user_exam_id,
      studentClassTicket,
      sessionClassExam,
      exam_id,
      user_id
    ) {
      console.log(
        user_exam_id,
        studentClassTicket,
        sessionClassExam,
        exam_id,
        user_id
      );
      this.studentExams = null;
      this.now_user_exam_id = user_exam_id;
      this.nowStudentClassTicket = studentClassTicket;
      this.nowSessionClassExamId = sessionClassExam;
      this.nowExamId = exam_id;
      this.now_user_id = user_id;
      this.getStudentsExams();
    },
    async getStudentsExams() {
      this.SATPage = 1;
      const res = await Tests.getStudentsExamsFromSAT(this.now_user_id, {
        per_page: 10,
        page: this.SATPage,
        exam_id: this.nowExamId,
        is_finished: null
      });
      this.studentExams = res.user_exams.data;
      this.pageTotal = res.user_exams.total;
      this.showSelectExam = true;
    },
    async changePage(page) {
      this.SATPage = page;
      const res = await Tests.getStudentsExamsFromSAT(this.now_user_id, {
        per_page: 10,
        page: this.SATPage,
        exam_id: this.nowExamId,
        is_finished: null
      });
      this.studentExams = res.user_exams.data;
      this.pageTotal = res.user_exams.total;
    },
    getPracticeTypeLink(category) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;
      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      if (category >= 15) {
        return "composePractices";
      } else if (category == 11 || category == 12) {
        return "singlePractice";
      } else if (category == 13 || category == 14) {
        return "singlePractice";
      } else {
        return "singlePractice";
      }
    },
    getType(category) {
      if (category > 14) {
        return "tests";
      } else {
        return "homework";
      }
    },
    getExamType(category) {
      if (category > 14) {
        return "composePractices";
      } else {
        return "singlePractice";
      }
    },
    async setExamAll(id, session_class_assign_test_id) {
      let student_user_ids = [];
      this.displayStudents.forEach(student => {
        student_user_ids.push(student.student.id);
      });
      this.$confirm("將要給所有學生開通新的考卷, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendAndSetExam(id, {
            student_user_ids: student_user_ids,
            session_class_assign_test_id
          });
          this.$message({
            message: "已開通！",
            type: "success"
          });
          this.$emit("getSessions");
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    async setExamUser(user_id, id, session_class_assign_test_id) {
      let student_user_ids = [user_id];
      this.$confirm("開通新的考卷, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendAndSetExam(id, {
            student_user_ids: student_user_ids,
            session_class_assign_test_id
          });
          this.$message({
            message: "已開通！",
            type: "success"
          });
          this.$emit("getSessions");
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    async sendExamMailAll(id, week) {
      let student_user_ids = [];
      this.displayStudents.forEach(student => {
        student_user_ids.push(student.student.id);
      });
      this.$confirm("給所有已經開通考卷的學生發送考試鏈接, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendExamMail(id, {
            student_user_ids: student_user_ids,
            which_week: week
          });
          this.$message({
            message: "發送成功！",
            type: "success"
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    async sendExamMail(user_id, id, week) {
      let student_user_ids = [user_id];
      this.$confirm("發送考試鏈接, 是否繼續?", "提示", {
        confirmButtonText: "繼續",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await Tests.sendExamMail(id, {
            student_user_ids: student_user_ids,
            which_week: week
          });
          this.$message({
            message: "發送成功！",
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    getScore(userExamId) {
      this.getUserExamInfo(userExamId);
    },
    async getUserExamInfo(userExamId) {
      const result = await Tests.getUserExamInfo(userExamId);
      this.form = result;
      this.startGrading();
    },
    async startGrading() {
      if (this.form.category == "Sat" || this.form.category == "Act") {
        this.routerPush({
          name: "GradingExam",
          query: {
            user_exam_id: this.form.user_exam_id
          }
        });
      } else if (this.form.category == "Compose Practice") {
        this.getTeacherClassExams();
      } else if (this.form.category == "Toefl") {
        this.getTeacherClassExams();
      }
    },
    async getTeacherClassExams() {
      const res = await Tests.getTeacherClassExams(this.form.exam_id);
      if (res.toefl_exam) {
        this.routerPush({
          name: "SetTest",
          query: {
            title: res.toefl_exam.title,
            testType: "mock",
            version: res.toefl_exam.type
          }
        });
      }
      if (res.compose_practice_id) {
        this.$router.push({
          name: "FullPractice",
          params: {
            fullPracticeId: res.compose_practice_id
          }
        });
      }
    },
    initialize() {
      this.getAssignTest();
      this.classes = this.setClasses(JSON.parse(JSON.stringify(this.section)));
    },
    async getAssignTest() {
      if (this.testState) {
        this.tests = this.testState.map(test => test);
      } else {
        const tests = await courseApi.getExams();
        this.tests = tests.exams;
      }
    },
    setClasses(sectionClass) {
      sectionClass["checkedAll"] = true;
      (sectionClass.student_class_tickets || []).forEach(student => {
        student["is_checked"] = true;
        let old_rscore = 0;
        let old_gscore = 0;
        let old_escore = 0;
        let old_mscore = 0;
        let old_score = 0;

        // 计算super score
        let first_rscore = 0;
        let first_gscore = 0;
        let first_escore = 0;
        let first_mscore = 0;
        let first_score = 0;

        let max_rscore = 0;
        let max_gscore = 0;
        let max_escore = 0;
        let max_mscore = 0;
        let max_score = 0;

        let count = 0;
        sectionClass.session_class_assign_tests.forEach((exam, index) => {
          let full_name = { full_name: "無" };
          sectionClass.exams.forEach(val => {
            if (val.id === exam.exam_id) {
              full_name = val;
            }
          });
          exam["full_name"] = full_name.full_name;
          exam["count"] = 0;
          exam["r_total"] = 0;
          exam["g_total"] = 0;
          exam["e_total"] = 0;
          exam["m_total"] = 0;
          exam["t_total"] = 0;

          exam["r_up"] = 0;
          exam["g_up"] = 0;
          exam["e_up"] = 0;
          exam["m_up"] = 0;
          exam["t_up"] = 0;

          if (
            student.student_class_ticket_exams !== undefined &&
            student.student_class_ticket_exams["Test" + index] !== undefined
          ) {
            if (
              student.student_class_ticket_exams["Test" + index].sat_score !==
                null &&
              student.student_class_ticket_exams["Test" + index]
                .user_exam_type === "App\\Entities\\UserExam"
            ) {
              count++;
              // student.student_class_ticket_exams["Test" + index].sat_score
              if (count === 1) {
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "rscore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "gscore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "escore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "mscore_up"
                ] = 0;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "score_up"
                ] = 0;
                //获取初次考试
                first_rscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .reading_score;
                first_gscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .writing_score;
                first_escore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_english_score;
                first_mscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_math_score;
                first_score =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .total_score;
              } else {
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "rscore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .reading_score - old_rscore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "gscore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .writing_score - old_gscore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "escore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_english_score - old_escore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "mscore_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_math_score - old_mscore;
                student.student_class_ticket_exams["Test" + index].sat_score[
                  "score_up"
                ] =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .total_score - old_score;
              }
              //Save Old Score
              old_rscore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .reading_score;
              old_gscore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .writing_score;
              old_escore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .section_english_score;
              old_mscore =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .section_math_score;
              old_score =
                student.student_class_ticket_exams["Test" + index].sat_score
                  .total_score;
              // Super score
              if (
                student.student_class_ticket_exams["Test" + index].sat_score
                  .reading_score > max_rscore
              ) {
                max_rscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .reading_score;
              }
              if (
                student.student_class_ticket_exams["Test" + index].sat_score
                  .writing_score > max_gscore
              ) {
                max_gscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .writing_score;
              }
              if (
                student.student_class_ticket_exams["Test" + index].sat_score
                  .section_math_score > max_mscore
              ) {
                max_mscore =
                  student.student_class_ticket_exams["Test" + index].sat_score
                    .section_math_score;
              }
            }
          }
        });
        max_escore = (max_rscore + max_gscore) * 10;
        max_score = max_escore + max_mscore;
        let max_rscore_up = max_rscore - first_rscore;
        let max_gscore_up = max_gscore - first_gscore;
        let max_escore_up = max_escore - first_escore;
        let max_mscore_up = max_mscore - first_mscore;
        let max_score_up = max_score - first_score;
        student["max_rscore"] = max_rscore;
        student["max_gscore"] = max_gscore;
        student["max_escore"] = max_escore;
        student["max_mscore"] = max_mscore;
        student["max_score"] = max_score;

        student["max_rscore_up"] = max_rscore_up;
        student["max_gscore_up"] = max_gscore_up;
        student["max_escore_up"] = max_escore_up;
        student["max_mscore_up"] = max_mscore_up;
        student["max_score_up"] = max_score_up;
      });
      this.getAvg(sectionClass);
      return sectionClass;
    },
    checked(section, student) {
      student.is_checked = !student.is_checked;
      let count = 0;
      section.student_class_tickets.forEach(test => {
        if (test["is_checked"] == true) {
          count++;
        }
      });
      if (section.student_class_tickets.length === count) {
        section.checkedAll = true;
      } else {
        section.checkedAll = false;
      }
      this.getAvg(section);
    },
    checkedAll(section) {
      section.checkedAll = !section.checkedAll;
      section.student_class_tickets.forEach(student => {
        student.is_checked = section.checkedAll;
      });
      this.getAvg(section);
    },
    getAvg(section) {
      let r_old = 0;
      let g_old = 0;
      let e_old = 0;
      let m_old = 0;
      let t_old = 0;
      (section.session_class_assign_tests || []).forEach((exam, index) => {
        exam.r_total = 0;
        exam.g_total = 0;
        exam.e_total = 0;
        exam.m_total = 0;
        exam.t_total = 0;
        exam.r_up = 0;
        exam.g_up = 0;
        exam.e_up = 0;
        exam.m_up = 0;
        exam.t_up = 0;
        exam["count"] = 0;
        exam["onlineCount"] = 0;
        (section.student_class_tickets || []).forEach(student => {
          if (student.is_checked) {
            if (
              student.student_class_ticket_exams !== undefined &&
              student.student_class_ticket_exams["Test" + index] !==
                undefined &&
              student.student_class_ticket_exams["Test" + index].sat_score !==
                null &&
              student.student_class_ticket_exams["Test" + index]
                .user_exam_type === "App\\Entities\\UserExam"
            ) {
              exam["count"]++;
              exam.r_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.reading_score;

              exam.g_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.writing_score;
              exam.e_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.section_english_score;
              exam.m_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.section_math_score;

              exam.t_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].sat_score.total_score;
            }
            if (
              student.student_class_ticket_exams !== undefined &&
              student.student_class_ticket_exams["Test" + index] !==
                undefined &&
              student.student_class_ticket_exams["Test" + index].user_exam &&
              student.student_class_ticket_exams["Test" + index].user_exam
                .is_finished == 1 &&
              student.student_class_ticket_exams["Test" + index].user_exam
                .result &&
              student.student_class_ticket_exams["Test" + index]
                .user_exam_type === "App\\Entities\\SatMocks\\UserExam"
            ) {
              exam["onlineCount"]++;
              exam.e_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].user_exam.result.english_score;
              exam.m_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].user_exam.result.math_score;

              exam.t_total +=
                student.student_class_ticket_exams[
                  "Test" + index
                ].user_exam.total_score;
            }
          }
        });

        if (t_old > 0) {
          if (exam["count"] > 0) {
            exam.r_up =
              this.showScore(exam.r_total * 10, exam["count"]) - r_old;
            exam.g_up =
              this.showScore(exam.g_total * 10, exam["count"]) - g_old;
            exam.e_up = exam.r_up + exam.g_up;
            exam.m_up = this.showScore(exam.m_total, exam["count"]) - m_old;
            exam.t_up = exam.r_up + exam.g_up + exam.m_up;
          }
        }
        r_old = this.showScore(exam.r_total * 10, exam["count"]);
        g_old = this.showScore(exam.g_total * 10, exam["count"]);
        e_old = r_old + g_old;
        m_old = this.showScore(exam.m_total, exam["count"]);
        t_old = e_old + m_old;
      });
    },
    showScore(total, count) {
      let score = "";
      if (count > 0) {
        score = Math.round(total / count);
      }
      return score;
    },
    async saveStudentExams() {
      let exams = [];
      for (let i in this.reportcards) {
        if (
          this.reportcards[i] !== undefined &&
          this.reportcards[i].exam_id > 0
        ) {
          if (this.reportcards[i].type === "satmocks") {
            exams.push({
              user_exam_type: "App\\Entities\\SatMocks\\UserExam",
              times: this.reportcards[i].which_week,
              exam_id: this.changeSATStudentExams[
                this.reportcards[i].onlineSATIndex
              ].exam_id,
              user_exam_id: this.changeSATStudentExams[
                this.reportcards[i].onlineSATIndex
              ].id
            });
          } else {
            if (
              this.reportcards[i].new_user_exam_id !==
              this.reportcards[i].user_exam_id
            ) {
              exams.push({
                user_exam_type: "App\\Entities\\UserExam",
                times: this.reportcards[i].which_week,
                exam_id: this.reportcards[i].new_exam_id,
                user_exam_id: this.reportcards[i].new_user_exam_id
              });
            }
          }
        }
      }
      if (exams.length > 0) {
        const res = await courseApi.saveStudentExams(
          this.activeStudent_class_ticket_id,
          {
            exams: exams
          }
        );
      }
      this.setTests = false;
      // this.$router.go(0);
    },
    async editReportcards(
      reportcards,
      student_class_ticket_id,
      student_user_id
    ) {
      this.reportcards = { ...reportcards };
      this.activeStudent_class_ticket_id = student_class_ticket_id;
      const res = await courseApi.getStudentExams(student_user_id);
      this.setTests = true;
      if (res.user_exams) {
        this.changeStudentExams = res.user_exams;
      } else {
        this.changeStudentExams = [];
      }
      if (res.sat_mocks_user_exams) {
        this.changeSATStudentExams = res.sat_mocks_user_exams;
      } else {
        this.changeSATStudentExams = [];
      }

      this.classes.session_class_assign_tests.forEach((item, index) => {
        if (this.reportcards["Test" + item.which_week] !== undefined) {
          // this.reportcards[i] = reportcards["Test" + i];
          this.reportcards["Test" + item.which_week]["can_correct"] = 0;
          this.reportcards["Test" + item.which_week][
            "new_user_exam_id"
          ] = this.reportcards["Test" + item.which_week]["user_exam_id"];
          this.reportcards["Test" + item.which_week][
            "new_exam_id"
          ] = this.reportcards["Test" + item.which_week]["exam_id"];
        } else {
          this.reportcards["Test" + item.which_week] = {
            act_score: null,
            can_correct: 0,
            created_at: "",
            exam: "",
            exam_id: item.exam_id,
            new_exam_id: item.exam_id,
            id: 0,
            sat_score: null,
            student_class_ticket_id: 0,
            updated_at: "",
            user_exam_id: 0,
            new_user_exam_id: 0,
            which_week: item.which_week,
            onlineSATIndex: null
          };
        }
        // this.reportcards["Test" + item.which_week]["type"] =  "ivyway";
      });
      this.reportcards = _.orderBy(this.reportcards, ["which_week", "asc"]);
      // for(let i in this.reportcards){
      //   if (this.reportcards[i] !== undefined) {
      //     // this.reportcards[i] = reportcards["Test" + i];
      //     this.reportcards[i]["can_correct"] = 0;
      //     this.reportcards[i]["new_user_exam_id"] =  this.reportcards[i]["user_exam_id"];
      //     this.reportcards[i]["new_exam_id"] =  this.reportcards[i]["exam_id"];
      //   }
      // }
    },
    async setExam(student, test, whichTest, course_session_id) {
      this.studentExam = {
        student_class_ticket_id: 0,
        test: "",
        student: "",
        whichTest: 0,
        exams: [],
        user_exam: "",
        exam_id: 0,
        sessionClass: 0
      };
      let exams = await courseApi.getStudentExams(student.student_user_id);
      this.studentExam.student_class_ticket_id = student.id;
      this.studentExam.exams = exams.user_exams;
      this.studentExam.student = student.first_name + " " + student.last_name;
      this.studentExam.test = test;
      this.studentExam.whichTest = whichTest;
      this.studentExam.sessionClass = course_session_id;
      this.setResult = true;
    },
    async saveStudentExam() {
      this.setResult = false;
      let userExam = this.studentExam.exams[this.studentExam.user_exam];
      await courseApi.setStudentExams(
        this.studentExam.student_class_ticket_id,
        {
          which_week: this.studentExam.whichTest,
          exam_id: userExam.exam_id,
          user_exam_id: userExam.id
        }
      );
      this.$emit("getSessions");
    },
    async removeStudentExam(student, which_week) {
      await courseApi.setStudentExams(
        student.student_class_ticket_exams["Test" + which_week]
          .student_class_ticket_id,
        {
          which_week: which_week,
          exam_id: 0,
          user_exam_id: 0
        }
      );
      this.$emit("getSessions");
    }
  }
};
</script>

<style scoped>
.table td {
  vertical-align: middle;
}

td,
th {
  /* 设置td,th宽度高度 */
  border: 1px solid gray;
  width: 100px;
  height: 30px;
}

table {
  table-layout: fixed;
  min-width: 200px; /* 固定宽度 */
}
th,
td {
  background-color: white;
}
td:first-child,
th:first-child {
  position: sticky;
  left: 0; /* 首行永远固定在左侧 */
  z-index: 1;
  width: 120px !important;
  background-color: white;
  border-right: 1px solid gray;
  text-align: center;
}
td:nth-child(2),
th:nth-child(2) {
  position: sticky;
  left: 120px; /* 首行永远固定在左侧 */
  min-width: 120px !important;
  z-index: 1;
  background-color: white;
  border-right: 1px solid gray;
}
thead tr th {
  position: sticky;
  top: 0; /* 列首永远固定在头部  */
}
th:first-child {
  z-index: 2;
  /* background-color:lightblue; */
}
</style>
